import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { App } from "antd";
import { CookiesProvider } from "react-cookie";

import Header from "./components/Navbar/Navbar";
import "./App.css";
import Home from "./Pages/Home";
import Spinner from "./components/Comman/Spinner";
import { AuthProvider } from "./API/AuthProvider";

function AppRoute() {
    const Fims = lazy(() => import("../src/routes/Fims"));

    return (
        <>
            <App>
                <BrowserRouter>
                    <Suspense
                        fallback={
                            <Spinner
                                text={"Making things Perfect Please wait"}
                                isLoading={true}
                            />
                        }
                    >
                        <CookiesProvider>
                            <AuthProvider>
                                <Fims />
                            </AuthProvider>
                        </CookiesProvider>
                    </Suspense>
                </BrowserRouter>
            </App>
        </>
    );
}

export default AppRoute;
