import React from 'react'
import TeamCard from '../components/TeamCard/TeamCard'
import Typography from 'antd/es/typography/Typography'
const {Text} = Typography
const Home = () => {
  return (
    <div>
      <Text>
        Welocome Back Tell me How can i help you
      </Text>
    </div>
  )
}

export default Home
