const reducer = (state=0, action) => {
    if(action.type === 'TAX_INVOICE'){
        const response = {
            success: 1,
            data: {
                firstName:"saalim",
                lastName: "bhatkar"
            }
        }
        return state + action.payload
    }
    else if(action.type === 'PRO_FORMA_INVOICE'){
        return state - action.payload
    }
    else{
        return state
    }
}

export default reducer;