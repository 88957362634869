import { React, useEffect, useState } from "react";
import { HiOutlineLogin } from "react-icons/hi";
import { UserOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";

import { auth } from "../../API/userApi";
import { Link, useNavigate } from "react-router-dom";
import {
    Drawer,
    Avatar,
    Button,
    Modal,
    Row,
    Form,
    Col,
    Input,
    Select,
    Image,
    Badge,
} from "antd";
import icon from "../../Assets/vipicon.png";
import homeIcon from "../../Assets/icon.png";
import { HiOutlineBellAlert, HiUser, HiPlus } from "react-icons/hi2";
import "./Header.css";
import { useAuth } from "../../API/AuthProvider";

import TextArea from "antd/es/input/TextArea";
const Header = () => {
    const { logoutUser } = useAuth();
    const [name, setName] = useState();
    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [open, setOpen] = useState(false);

    const logout = () => {
        logoutUser();
        navigate("/signin");
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const selectTeam = (value) => {
        setTask({ ...task, team: value });
    };

    const selectTeamMember = (value) => {
        setTask({ ...task, assigne: value });
    };

    const [task, setTask] = useState({
        taskName: "",
        description: "",
        team: "",
        assigne: "",
    });
    // calling the task Creator api
    // let response =  taskCreator(task)

    return (
        <header
            style={{
                display: "flex",
                backgroundColor: "#f0f0f0",
                height: "3rem",
                color: "blue",
                top: 0,
                zIndex: 10,
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                    }}
                >
                    <img
                        className="company_image"
                        alt="companylogo"
                        src={icon}
                        style={{
                            width: "3rem",
                        }}
                    />
                    VIP PLAY AND ENGINEERING
                </div>

                <div style={{ marginLeft: "30vw" }}>
                    <Avatar
                        size={"small"}
                        style={{ background: "#52c41a" }}
                        icon={<HiPlus />}
                    />
                </div>
                <div style={{ marginLeft: "20vw" }}>
                    <Badge
                        size={"small"}
                        style={{ background: "blue" }}
                        count={1}
                    >
                        <Avatar
                            size={"small"}
                            icon={<HiOutlineBellAlert />}
                            style={{
                                background: "#52c41a",
                                alignItems: "center",
                            }}
                        />
                    </Badge>
                    <Avatar
                        style={{ marginLeft: "2vw", backgroundColor: "#000" }}
                        onClick={showDrawer}
                        icon={<HiUser />}
                    />
                </div>
            </div>

            <Drawer
                className="drawer"
                open={open}
                width={"17vw"}
                onClose={onClose}
                style={{
                    backgroundColor: "#f0f0f0",
                }}
            >
                <div className="company_profile">
                    <p className="profile_icon">
                        <Avatar
                            style={{
                                backgroundColor: "#87d068",
                            }}
                            icon={<UserOutlined />}
                        />
                    </p>
                    <h3>{name}</h3>
                </div>
                <div className="company_profile">
                    <p className="profile_icon">
                        <Avatar
                            style={{
                                backgroundColor: "#ff0000",
                            }}
                            icon={<LogoutOutlined />}
                        />
                    </p>
                    <a href="">
                        <Button onClick={logout} type="primary">
                            Log Out
                        </Button>
                    </a>
                </div>
                <div className="company_profile">
                    <Link to="/updatepassword">
                        <Button
                            className="profile_icon"
                            onClick={() => setOpen(false)}
                        >
                            Update Password
                        </Button>
                    </Link>
                </div>

                <div className="company_profile">
                    <Link to="/Adduser">
                        <Button
                            className="profile_icon"
                            onClick={() => setOpen(false)}
                        >
                            Add user
                        </Button>
                    </Link>
                </div>
            </Drawer>
        </header>
    );
};

export default Header;
