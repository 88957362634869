import { createContext, useContext, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
// import NotificationHandler from "EventHandler/NotificationHandler";
import axios from "axios";
import { message } from "antd";
// import { removeLocalData } from "Helper/FetchingLocalData";
let myfac8ryBaseUrl = process.env.REACT_APP_PROD_URL;
if (process.env.NODE_ENV === "development") {
    myfac8ryBaseUrl = process.env.REACT_APP_LOCAL_URl;
}
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const loginUser = (response) => {
        const { token } = response;
        setCookie("token", token, { maxAge: 360000 });
    };

    const logoutUser = () => {
        removeCookie("token");
        // removeLocalData("customer");
        // removeLocalData("invoice")
        // removeLocalData("payments");
        // removeLocalData("quote");
        // removeLocalData("challan");
        // removeLocalData("lead");
        // removeLocalData("expenses");
        // removeLocalData("vendors");
        // removeLocalData("deliverychallan");
        // removeLocalData("purchaseorder");
        // removeLocalData("vendors");
    };

    const authApiCall = async (path, data) => {
        let token = cookies["token"];

        let axiosConfig = {
            url: myfac8ryBaseUrl + `myfac8ry/${path}`,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                token: token ? token : null,
            },
            data: data,
        };
        try {
            let response = await axios(axiosConfig);
            console.log(response, axiosConfig, "response");
            return response.data;
        } catch (error) {
            let response = {
                success: 0,
                result: null,
                message: error.response
                    ? error.response.data.message
                    : "NetWork Error",
                error: error.message,
            };
            return response;
        }
    };

    const appApiCall = async (method, path, payload, param, query) => {
        let token = cookies["token"];
        let axiosConfig = {
            url: myfac8ryBaseUrl + `myfac8ry/${path}`,
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: token ? token : null,
            },
            data: payload ? payload : null,
            params: param ? param : null,
        };

        if (payload instanceof FormData) {
            axiosConfig.headers["Content-Type"] = "multipart/form-data";
            axiosConfig.data = payload;
        }

        if (param) {
            axiosConfig.url = `${axiosConfig.url}/${query}`;
        }
        if (method === "get") {
            axiosConfig.params = payload;
            axiosConfig.body = {};
        }

        try {
            let response = await axios(axiosConfig);
            return response.data;
        } catch (error) {
            let response = {
                success: 0,
                result: null,
                message: error.response
                    ? error.response.data.message
                    : "NetWork Error",
            };
            return response;
        }
    };
    const getListData = async (entity, fieldName) => {
        let data = await appApiCall("post", "getListData", {
            entity: entity,
            fieldName: fieldName,
        });
        if (data.success === 0) {
            return [];
        } else {
            return data.result;
        }
    };

    const getDropDownData = async (entity, fieldName) => {
        let data = await appApiCall("post", "getDropDownData", {
            entity: entity,
            fieldName: fieldName,
        });
        if (data.success === 0) {
            return [];
        } else {
            return data.result;
        }
    };

    const patchData = async (payload, param) => {
        const data = await appApiCall("patch", `patch`, payload);
        try {
            const config = {
                method: "post",
                url: `${myfac8ryBaseUrl}myfac8ry/patch/${param}`,
                data: payload,
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": `${cookies.token ? cookies.token : null}`,
                },
            };

            let { data } = await axios(config);
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const addNewDropDownData = async (payload, path) => {
        let data = await appApiCall("post", "addDropDownData", payload);
        if (data.success === 0) {
            return { success: 0, result: null, message: data.message };
        } else {
            return { success: 1, result: data.result, message: data.message };
        }
    };

    const createData = async (payload) => {
        let data = await appApiCall("post", "create", payload);
        if (data.success === 0) {
            return { success: 0, result: null, message: data.message };
        } else {
            return { success: 1, result: data.data, message: data.message };
        }
    };

    const readData = async (params) => {
        let data = await appApiCall("get", "get", {}, params);
        if (data.success === 0) {
            return { success: 0, result: null, message: data.message };
        } else {
            return {
                success: 1,
                result: data.result,
                message: data.message,
                total: data.total,
            };
        }
    };

    const deleteData = async (entity, _id) => {
        try {
            const config = {
                method: "post",
                url: `${myfac8ryBaseUrl}myfac8ry/delete`,
                data: {
                    entity: entity,
                    _id: _id,
                },
                headers: {
                    "Content-Type": "application/json",
                    token: `${cookies.token ? cookies.token : null}`,
                },
            };
            let { data } = await axios(config);
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                loginUser,
                logoutUser,
                authApiCall,
                appApiCall,
                // adminApiCall,
                getDropDownData,
                getListData,
                addNewDropDownData,
                // getTableData,
                createData,
                readData,
                deleteData,
                patchData,
                // updateData,
                // pdfGenrate,
                // patchData,
                // uploadFile
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
