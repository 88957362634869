import axios  from "axios";
import {Env}  from "../API/env"

let apiUrl = Env()

export  const auth = async () =>{
  let token = localStorage.getItem("token")
    const config = {
      method: "post",
      url: `${apiUrl}myfac8ry/authUser`,
      headers: {
        "Content-Type": "application/json",
        "auth-token": `${token}`,
      },
      data: JSON.stringify({}),
    };

    try {
      let response = await axios(config);
      return response.data
    } catch (error) {
      window.location.replace("/signin")
    }
  }

export const signin = async  (login) =>{

    const config = {
      method: "post",
      url: `${apiUrl}myfac8ry/signin`,
      headers: {
        "Content-Type": "application/json",
      },
      data:login
    };
    let response = null
    try {
      response = await axios(config)
    } catch (error) {
      console.log(error);
      response.data = null
    }
    return response.data;

}

export const taskCreator = async (payload) => {
  const config = {
    method: "post",
    url: `${apiUrl}myfac8ry/taskCreator`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({payload}),
  };

  try {
    let response = await axios(config);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkingPassword = async (email, oldPassword) => {
  try {
    const config = {
      method: "post",
      url: "myfac8ry/checkingPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data:JSON.stringify({
        email: email,
        oldPassword: oldPassword,
      }),
    };

    let data = await axios(config);
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePassword = async (newPassword,email,oldPassword) => {
  try {
    const config = {
      method: "post",
      url:`${apiUrl}myfac8ry/updatePassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        email:email,
        newPassword: newPassword,
        oldPassword:oldPassword
      }),
    };

    let data = await axios(config);
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

